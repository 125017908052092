<template>
  <div id="emergencies">
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />事件管理
        </van-col>
        <!-- <van-col span="14" style="text-align: right" @click="onIssueClick">
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col> -->
      </van-row>
      <van-row class="tabs">
        <van-col
          span="6"
          v-for="(item, index) in tabs"
          :key="index"
          :class="[item.type == type ? 'tab-item-active' : 'tab-item']"
          @click="onTabClick(item)"
          >{{ item.text }}</van-col
        >
      </van-row>
    </div>
    </van-sticky>
    <div class="emergencies-container" v-if="type == 1">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_emergencies"
        >
          <div
            
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
            :class="[item.is_approved?'emergencies-item-read':'emergencies-item']"
          >
          <van-row>
              <van-col span="12">
                <div class="emergencies-item-title van-ellipsis">{{ item.reporter_name }} 
                  <svg-icon
                  v-if="item.reportable_type=='Guide'"
                    :data_iconName="'guider'"
                    :className="'guider_icon'"
                  />
                  <svg-icon
                   v-if="item.reportable_type=='TravelCompany'"
                    :data_iconName="'agency'"
                    :className="'agency_icon'"
                  />
                  </div>
              </van-col>
              <van-col span="12">
                <div class="emergencies-item-date" style="text-align: right">
                  <van-tag v-if="item.approve_status=='1'" size="large" mark type="primary" text-color="#40a9ff" color="rgba(64, 169, 255, 0.16)">{{item.approve_status_text}}</van-tag>
                  <van-tag v-if="item.approve_status=='2'" size="large" mark type="primary" text-color="#D52B4D" color="rgba(64, 169, 255, 0.16)">{{item.approve_status_text}}</van-tag>
                </div>
              </van-col>
            </van-row>
           
            <!-- <div class="emergencies-item-title van-ellipsis">{{ item.reporter_name }}</div> -->
            <div class="emergencies-item-body van-multi-ellipsis--l2">
             {{ item.report_content }}
            </div>
            <van-row>
              <van-col span="12">
                <div class="emergencies-item-date" style="text-align: left">
                   <svg-icon
               style="margin-right:5px;"
               v-if="item.report_type_text=='疫情事件'"
            :data_iconName="'yiqing'"
            :className="''"
          />{{ item.travel_list }}
                </div>
              </van-col>
              <van-col span="12">
                <div class="emergencies-item-date" style="text-align: right">{{ item.created_at }}</div>
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <div class="feedbacks-wrapper" v-if="type == 2">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh3">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_tousu"
        >
          <div
            class="feedbacks-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <van-row @click="onTousuClick(item)">
              <van-col span="16" class="text-ellipsis">
                <span>
                  {{
                    item.feedback_content ? item.feedback_content : "暂无评价内容"
                  }}
                </span>
              </van-col>
              <van-col span="8" class="text-right">
                <van-tag round :type="item.approve_status == null? 'warning'
                :item.approve_status == 1? 'primary': 'danger'"
                :color="item.approve_status == 1?'rgba(64, 169, 255, 0.16)'
                  : item.approve_status == 2? '#f9f0f5': '#f5f5f5'"
                :text-color="item.approve_status == 1?'#40a9ff'
                  :item.approve_status == 2? '#D52B4D' : '#666666'">
                  {{item.approve_status == null? '未处理': item.approve_status_text}}
                </van-tag>
              </van-col>
              <van-col
                span="12" class="margin-top-10"
                style="color: #666; font-size: 0.2rem; text-align: left"
              >
                {{ item.feedback_object.object }}
              </van-col>
              <van-col
                span="12" class="margin-top-10"
                style="color: #666; font-size: 0.2rem; text-align: right"
              >
                {{ item.feedback_object.name }}
              </van-col>
              <van-col span="12" style="color: #666; font-size: 0.2rem">
                {{ item.user_name }}
              </van-col>
              <van-col
                span="12"
                style="color: #666; font-size: 0.2rem; text-align: right"
              >
                {{ item.created_at }}
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <div class="feedbacks-wrapper" v-if="type == 3">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh1">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_feedbacks"
        >
          <div
            class="feedbacks-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <van-row @click="onItemFeedClick(item)">
              <van-col span="24">
                {{
                  item.feedback_content ? item.feedback_content : "暂无评价内容"
                }}
              </van-col>
              <van-col span="24">
                <van-tag
                  style="margin-right: 3px"
                  round
                  type="primary"
                  v-for="(i, index) in item.tags"
                  :key="index"
                  >{{ i }}</van-tag
                >
              </van-col>
              <van-col span="24">
                <van-rate
                  v-model="item.rate_star"
                  :size="25"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                  readonly
                  allow-half
                />
              </van-col>
              <van-col
                span="12"
                style="color: #666; font-size: 0.2rem; text-align: left"
              >
                {{ item.feedback_object.object }}
              </van-col>
              <van-col
                span="12"
                style="color: #666; font-size: 0.2rem; text-align: right"
              >
                {{ item.feedback_object.name }}
              </van-col>
              <van-col span="12" style="color: #666; font-size: 0.2rem">
                {{ item.user_name }}
              </van-col>
              <van-col
                span="12"
                style="color: #666; font-size: 0.2rem; text-align: right"
              >
                {{ item.created_at }}
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <div class="archive-infos-container" v-if="type == 4">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh2">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_archive_infos"
        >
         <van-row v-for="(item,index) in list" :key="index" class="archive-infos" @click="onArchiveInfoClick(item)">
          <van-col span="6" class="archive-info-title">游客：</van-col>
           <van-col span="18" class="archive-info-text">{{item.user_name}}</van-col>
           <van-col span="6" class="archive-info-title">团名：</van-col>
           <van-col span="18"  class="archive-info-text">{{item.travel_list_name}}</van-col>
           <van-col span="6" class="archive-info-title">入住地址：</van-col>
           <van-col span="18"  class="archive-info-text">{{item.live_address}}</van-col>
           <!-- <van-col span="6" class="archive-info-title">目的地：</van-col>
           <van-col span="18"  class="archive-info-text">{{item.destination_address}}</van-col> -->
          
           <van-col span="6" class="archive-info-title">备案时间：</van-col>
           <van-col span="18"  class="archive-info-text">{{item.created_at}}</van-col>
         </van-row>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <Tarbar :active="4"></Tarbar>
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "emergencies",
  components: { Tarbar },
  data() {
    const _self = this
    return {
      list: [],
      active: 1,
      type: _self.$route.query.type? _self.$route.query.type:1,
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
      },
      query1: {
        page: 1,
        feedback_type: 1
      },
      query2: {
        page: 1,
      },
      query3: {
        page: 1,
        feedback_type: 3
      },
      tabs: [
        {
          text: "事件",
          type: 1,
        },{
          text: "投诉",
          type: 2,
        },
        {
          text: "评价",
          type: 3,
        },
        {
          text: "行程备案",
          type: 4,
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push('/');
    },
    onItemFeedClick(item) {
      this.$router.push({
        path: "/feedbacks/item",
        query: {
          id: item.id,
          type:this.type
        },
      });
    },
    onTousuClick(item) {
      this.$router.push({
        path: "/feedbacks/tousu",
        query: {
          id: item.id,
          type:this.type
        },
      });
    },
    onItemClick(item) {
      this.$router.push({
        path: "/emergencies/item",
        query: {
          id: item.id,
          type:this.type
        },
      });
    },
    onArchiveInfoClick(item){
      this.$router.push({
        path: "/archive-info/item",
        query: {
          id: item.id,
          type:this.type
        },
      });
    },
    onTabClick(item) {
      this.type = item.type;
      if (item.type == 1) {
        this.onRefresh();
      } 
      if (item.type == 2) {
        this.onRefresh3();
      } 
      if(item.type==3) {
        this.onRefresh1();
      }
      if(item.type==4) {
        this.onRefresh2();
      }
    },
    async get_emergencies() {
      const res = await this.$apis.get_emergencies(this.query);
      if (res.next_page) {
        this.query.page++;
        // this.list.concat(res.data);

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // this.list = res.emergencies;
      // for (var i = 0; i < res.emergencies.length; i++) {
      //   this.list.push(res.emergencies[i]);
      // }
       this.list = [...this.list, ...res.emergencies]
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_emergencies();
    },
    async get_feedbacks() {
      const res = await this.$apis.get_feedbacks(this.query1);
      if (res.next_page) {
        this.query1.page++;
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // this.list = res.emergencies;
      // for (var i = 0; i < res.data.length; i++) {
      //   this.list.push(res.data[i]);
      // }
      this.list = [...this.list, ...res.data]
    },
    onRefresh1() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query1.page = 1;
      this.loading = true;
      this.get_feedbacks();
    },
    async get_archive_infos(){
       const res = await this.$apis.get_archive_infos(this.query2);
        if (res.next_page) {
          this.query2.page++;
          this.loading = false;
          this.refreshing = false;
        } else {
          this.finished = true;
          this.loading = false;
          this.refreshing = false;
        }
        // this.list = res.emergencies;
        // for (var i = 0; i < res.data.length; i++) {
        //   this.list.push(res.data[i]);
        // }
        this.list = [...this.list, ...res.data]
    },
    onRefresh2() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query2.page = 1;
      this.loading = true;
      this.get_archive_infos();
    },
    async get_tousu() {
      const res = await this.$apis.get_feedbacks(this.query3);
      if (res.next_page) {
        this.query3.page++;
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // this.list = res.emergencies;
      // for (var i = 0; i < res.data.length; i++) {
      //   this.list.push(res.data[i]);
      // }
      this.list = [...this.list, ...res.data]
    },
    async onRefresh3() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query3.page = 1;
      this.loading = true;
      this.get_tousu()
    },
  },
  created() {
    if (this.type == 1) {
        this.onRefresh();
      } 
      if (this.type == 2) {
        this.onRefresh3();
      } 
      if(this.type==3) {
        this.onRefresh1();
      }
      if(this.type==4) {
        this.onRefresh2();
      }
    
  },
};
</script>

<style scoped>
#emergencies {
  height: 100vh;
  /* padding-top:20vh; */
}
.header {
  height: 20vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.feedbacks-wrapper,
.emergencies-container,.archive-infos-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 5rem;
  position: fixed;
  top:20vh;
  left: 0;
  width: 100vw;
}
.emergencies-item,.archive-infos {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.2rem .3rem;
  position: relative;
  margin-bottom: 10px;
}
.emergencies-item-read{
  background: #f1f1f1;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.2rem .3rem;
  position: relative;
  margin-bottom: 10px;
}
.emergencies-item-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
.emergencies-item-body {
  font-size: 14px;
  color: #666666;
  padding: 10px 0;
  height: 50px;
  overflow: hidden;
}
.emergencies-item-date {
  font-size: 12px;
  color: #999999;
}
.feedbacks-item {
  padding: 0.3rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
  position: relative;
  font-size: 0.3rem;
  margin-bottom: 10px;
}
.archive-info-title{
  color:#333;
  font-size: 14px;
    font-weight: bold;
}
.archive-info-text{
  color:#666;
  font-size: 14px;
}
</style>
